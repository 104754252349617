import React from 'react';
import { Link } from 'gatsby';
import Logo from '../images/logo.svg';
import style from '../stylesheets/menu.module.css';

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: `${style.link} ${style.active}` }
    : { className: style.link };
};

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.children}
  </Link>
);

function Menu() {
  return (
    <header>
      <div className={style.menu}>
        <ul className={style.items}>
          <li className={style.item}>
            <Link className={style.logo} to={'/'}>
              <span>Home</span>
              <Logo />
            </Link>
          </li>
          <li className={style.item}>
            <PartialNavLink to={'/about/'}>
              <span>About</span>
            </PartialNavLink>
          </li>
          <li className={style.item}>
            <PartialNavLink to={'/articles/'}>
              <span>Articles</span>
            </PartialNavLink>
          </li>
          <li className={style.item}>
            <a className={style.link} href="https://portfolio.craigmdennis.com">
              <span>Portfolio</span>
            </a>
          </li>
          <li className={style.item}>
            <PartialNavLink to={'/contact/'}>
              <span>Contact</span>
            </PartialNavLink>
          </li>
          <li className={style.item}>
            <a className={style.link} href="https://blacklivesmatter.com/">
              <span>#blacklivesmatter</span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Menu;
