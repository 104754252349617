import React from 'react';
import Menu from './menu';
import Fonts from './fonts';
import style from '../stylesheets/layout.module.css';

const Layout = props => {
  const { children } = props;

  return (
    <div className={style.wrapper}>
      <Fonts
        families={
          'family=PT+Sans:wght@400;700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700'
        }
      />
      <Menu />
      <div className={props.wide ? style.wide : style.narrow}>
        <main>{children}</main>
      </div>
      <footer>
        <div className={style.footer}>
          <div className={style.narrow}>
            &copy; {new Date().getFullYear()}. Built with{' '}
            <a className={style.footerLink} href="https://www.gatsbyjs.org">
              Gatsby
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
